import React, {useState} from 'react';
import './nav-bar.scss';
import '../../styles/typography.scss'
import { Link,NavLink } from 'react-router-dom';

const NavBar = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  return (
    <nav>
      <Link className='title' to="/work">
        <h3>Ravin Perera</h3>
      </Link>
      <div className='menu' onClick={() => setMenuOpen(!menuOpen)}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <ul className={menuOpen ? "open" : ""}>
        <li>
          <NavLink to="/">Work</NavLink>
        </li>
        <li>
          <NavLink to="/about">About</NavLink>
        </li>
        <li>
          <NavLink to="https://drive.google.com/file/d/1RYH37Ryh5T94VMHpztw_mZ0IdL4Ks4s5/view?usp=sharing" target='_blank'>Resume</NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default NavBar